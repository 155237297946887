<template>
  <div>
    <payroll-list-filter :status-options="statusOptions" />
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="6"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>
          <b-col cols="6" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative cursor-pointer"
        :items="fetchPayrolls"
        responsive
        :fields="tableColumns"
        :busy="busy"
        show-empty
        empty-text="No matching records found"
        @row-clicked="myHandler"
      >
        <!-- Column: customer -->
        <template #cell(customer)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar size="32" :src="data.item.avatar" :variant="'light-primary'" />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ data.item.customer.customerName }}
            </span>
            <small class="text-muted">{{ data.item.customer.customerCompany }}</small>
          </b-media>
        </template>
        <!-- Column: Approve amount -->
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatus(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(createdAt)="data">
          <format-time :time="data.item.createdAt" />
        </template>
        <template #cell(totalAmount)="data">
          <p>{{ numberWithCommas(data.item.totalAmount) }}</p>
        </template>
        <template #cell(totalServiceFee)="data">
          <p>{{ numberWithCommas(data.item.totalServiceFee) }}</p>
        </template>
        <template #cell(salaryDate)="data">
          <format-time
            v-if="data.item.status >= 6"
            :time="data.item.salaryDate"
            format-type="DD/MM/YYYY"
          />
          <format-time v-else :time="data.item.desiredDisbursedDate" format-type="DD/MM/YYYY" />
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex">
            <b-button
              v-if="
                $can(
                  permissionCode.PAYROLL_DOWNLOAD.action,
                  permissionCode.PAYROLL_DOWNLOAD.resource,
                )
              "
              variant="secondary"
              style="margin: 5px"
              @click="hanldeDownload(data.item)"
              ><feather-icon icon="DownloadIcon"
            /></b-button>
            <b-button
              v-if="
                data.item.status === 11 &&
                $can(permissionCode.PAYROLL_APPROVE.action, permissionCode.PAYROLL_APPROVE.resource)
              "
              variant="primary"
              style="margin: 5px"
              @click="hanldeApprove(data.item.id)"
              >Duyệt</b-button
            >
            <b-button
              v-if="
                data.item.status === 4 &&
                $can(permissionCode.PAYROLL_INVEST.action, permissionCode.PAYROLL_INVEST.resource)
              "
              v-b-modal.modal-invest
              variant="info"
              style="margin: 5px"
              @click="clickInvest(data.item.id)"
              >Đầu tư</b-button
            >
            <b-button
              v-if="
                data.item.status === 5 &&
                $can(
                  permissionCode.PAYROLL_DISBURSE.action,
                  permissionCode.PAYROLL_DISBURSE.resource,
                )
              "
              v-b-modal.modal-disburse
              variant="primary"
              style="margin: 5px"
              @click="clickDisburse(data.item.id)"
              >Giải ngân</b-button
            >
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalContract"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-modal ref="modal-invest" title="Chọn nhà đầu tư" @ok="handleInvest()">
      <vue-autosuggest
        :suggestions="filteredOptions"
        :limit="10"
        :input-props="{
          id: 'autosuggest__input',
          class: 'form-control',
          placeholder: 'Chọn nhà đầu tư',
        }"
        :get-suggestion-value="getSuggestionValue"
        @input="onInputChange"
      >
        <template slot-scope="{ suggestion }">
          <span class="my-suggestion-item">{{ suggestion.item.fullName }}</span>
        </template>
      </vue-autosuggest>
    </b-modal>
    <b-modal
      ref="modal-disburse"
      title="Giải ngân"
      :ok-disabled="disableDisbursement"
      @ok="handleDisburse()"
    >
      <div>
        <b-form-group label="File giải ngân">
          <input
            id="assetsFieldHandle"
            ref="file"
            type="file"
            class="w-px h-px"
            accept=".xlsx,.xls"
            @input="validateFile"
        /></b-form-group>

        <b-form-group label="Ngày giải ngân">
          <b-form-datepicker
            v-model="disbursementDay"
            :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
            placeholder="Chọn ngày giải ngân"
            locale="en-GB"
          />
        </b-form-group>
        <div class="text-center">
          <a
            style="color: #ff9800; font-size: 14px"
            href="/example/disbursement_example.xlsx"
            download
          >
            <feather-icon icon="DownloadIcon" />
            Tải file mẫu
          </a>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormDatepicker,
  BFormGroup,
  BModal,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import * as xlsx from 'xlsx';
import { VueAutosuggest } from 'vue-autosuggest';
import * as moment from 'moment';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import permissionCode from '@/constants/permissionCode';
import constRouter from '@/constants/constRouter';
import {
  getLinkPayroll,
  approvePayroll,
  investPayroll,
  disbursementPayroll,
  repaymentConfirm,
} from '@/apis/apiPayroll';
import { numberWithCommas } from '@core/utils/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import FormatTime from '@core/components/format-time/FormatTime.vue';
import { customerList } from '@/apis/apiCustomer';
import PayrollListFilter from './PayrollListFilter.vue';
import usePayrollList from './usePayrollList';
import payrollStoreModule from '../payrollStoreModule';

const headerInstance = [
  'Mã hợp đồng Jarvis',
  'CMND',
  'Mã transaction',
  'Trạng thái',
  'Thời gian chuyển (08:43:48 30/12/2022)',
];

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BFormDatepicker,
    BFormGroup,
    vSelect,
    VueAutosuggest,
    PayrollListFilter,
    FormatTime,
  },
  setup(props, { root: { $eventBus } }) {
    const PAYROLL_APP_STORE_MODULE_NAME = 'app-payroll';
    // Register module
    if (!store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
      store.registerModule(PAYROLL_APP_STORE_MODULE_NAME, payrollStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PAYROLL_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PAYROLL_APP_STORE_MODULE_NAME);
      }
    });
    const isAddNewUserSidebarActive = ref(false);
    const {
      fetchPayrolls,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,
      refUserListTable,
      // UI
      resolveUserStatusVariant,
      // filter
      statusOptions,
      resolveStatus,
    } = usePayrollList();

    $eventBus.on('payroll:filter:update', refetchData);

    // UnRegister on leave
    onUnmounted(() => {
      $eventBus.off('contract:filter:update', refetchData);
    });

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      refUserListTable,
      fetchPayrolls,
      tableColumns,
      perPage,
      currentPage,
      totalContract,
      dataMeta,
      perPageOptions,
      refetchData,

      // Filter
      statusOptions,

      // UI
      resolveUserStatusVariant,
      resolveStatus,
      numberWithCommas,

      moment,
      permissionCode,
    };
  },
  data() {
    return {
      lenderId: '',
      contractId: '',
      datasuggest: [],
      filteredOptions: [],
      disbursementDay: '',
      limit: 10,
      selected: null,
      busy: false,
      disableDisbursement: true,
    };
  },
  created() {
    customerList({
      // filter ndt
      type: 1,
    })
      .then((res) => {
        this.datasuggest = res.data?.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    validateFile() {
      const reader = new FileReader();
      reader.readAsBinaryString(this.$refs.file.files[0]);
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = xlsx.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[1];
        const ws = wb.Sheets[wsname];
        const header = xlsx.utils.sheet_to_json(ws, { header: 1 })[0];
        if (header.length) {
          this.disableDisbursement = header.some(
            (item, idx) => !item.includes(headerInstance[idx]),
          );
          if (this.disableDisbursement) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Định dạng file không hợp lệ',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            });
          }
        } else {
          this.disableDisbursement = true;
        }
      };
    },
    hanldeDownload(item) {
      getLinkPayroll(item.id)
        .then((res) => {
          const url = window.URL.createObjectURL(res.data);

          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `importUserILuong_${item.code}.xlsx`);
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    hanldeApprove(id) {
      this.busy = true;
      approvePayroll(id)
        .then((res) => {
          this.busy = false;
          setTimeout(() => {
            this.refetchData();
          }, 50);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Duyệt HĐ thành công',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.busy = false;
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Duyệt HĐ thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    clickInvest(id) {
      this.contractId = id;
      this.$refs['modal-invest'].show();
    },
    clickDisburse(id) {
      this.contractId = id;
      this.$refs['modal-disburse'].show();
    },
    handleInvest() {
      this.busy = true;
      investPayroll(this.contractId, {
        lenderId: this.lenderId,
      })
        .then((res) => {
          this.busy = false;
          setTimeout(() => {
            this.refetchData();
          }, 50);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Đầu tư thành công',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Đầu tư thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    handleDisburse() {
      this.busy = true;
      const form = new FormData();
      form.append('disburseFile', ...this.$refs.file.files);
      form.append('disbursedDate', this.disbursementDay);
      disbursementPayroll(this.contractId, form)
        .then(() => {
          this.busy = false;
          setTimeout(() => {
            this.refetchData();
          }, 50);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giải ngân thành công',
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          });
        })
        .catch((err) => {
          console.log(err);
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Giải ngân thất bại',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: err.response.data?.message,
            },
          });
        });
    },
    onInputChange(text) {
      if (text === '' || text === undefined) {
        this.filteredOptions = [];
        return;
      }

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.datasuggest
        .filter((item) => item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1)
        .slice(0, this.limit);
      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    getSuggestionValue(suggestion) {
      this.lenderId = suggestion.item.id;
      return suggestion.item.fullName;
    },
    myHandler(val) {
      this.$router.push({
        name: constRouter.PAYROLL_DETAIL.name,
        params: {
          id: val.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
