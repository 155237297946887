<template>
  <div>
    <b-form-group class="mb-0" style="flex: 1">
      <label>{{ $t(title) }}</label>
      <b-form-input v-model="value" class="" />
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue';

export default {
  name: 'PayrollFilterInput',
  components: {
    BFormGroup,
    BFormInput,
  },
  props: {
    title: {
      type: String,
      default: 'payrollFilterDropdown',
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: null,
      debounce: null,
    };
  },
  watch: {
    value(newValue) {
      if (newValue !== this.$router.currentRoute.query[this.query]) {
        this.debounceChange(newValue);
      }
    },
  },
  created() {
    this.value = this.$router.currentRoute.query[this.query];
    this.$eventBus.on('payroll:filter:clear', this.listenEvent);
  },
  onUnmounted() {
    this.$eventBus.off('payroll:filter:clear', this.listenEvent);
  },
  methods: {
    debounceChange(value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.updateFilter(value);
      }, 1000);
    },
    updateFilter(value) {
      if (value !== this.$router.currentRoute.query[this.query]) {
        const query = { ...this.$router.currentRoute.query };
        if (typeof value !== 'undefined' && value !== null && value !== '') {
          query[this.query] = value;
        } else {
          delete query[this.query];
        }
        this.$router.push({ query });
        this.$eventBus.emit('payroll:filter:update');
      }
    },
    listenEvent() {
      this.value = null;
    },
  },
};
</script>
