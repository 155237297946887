<template>
  <dropdown-filter
    :title="title"
    :options="options"
    :selected="selected"
    @change="changeSelected"
  />
</template>

<script>
import DropdownFilter from '@/components/filter/DropdownFilter.vue';

export default {
  name: 'PayrollFilterDropdown',
  components: {
    DropdownFilter,
  },
  props: {
    title: {
      type: String,
      default: 'ContractFilterDropdown',
    },
    query: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      selected: null,
    };
  },
  created() {
    this.selected = +this.$router.currentRoute.query[this.query];
    this.$eventBus.on('payroll:filter:clear', this.listenEvent);
  },
  onUnmounted() {
    this.$eventBus.off('payroll:filter:clear', this.listenEvent);
  },
  methods: {
    changeSelected(value) {
      this.selected = value;
      if (value !== this.$router.currentRoute.query[this.query]) {
        const query = { ...this.$router.currentRoute.query };
        if (typeof value !== 'undefined' && value !== null && value !== '') {
          query[this.query] = value;
        } else {
          delete query[this.query];
        }
        this.$router.push({ query });
        this.$eventBus.emit('payroll:filter:update');
      }
    },
    listenEvent() {
      this.selected = null;
    },
  },
};
</script>
