import { ref, watch, computed } from '@vue/composition-api';
import store from '@/store';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';

export default function useUsersList() {
  // Use toast
  const toast = useToast();
  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    {
      key: 'code',
      label: 'Mã HĐ',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'companyName',
      label: 'Công ty',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'totalAmount',
      label: 'Tiền ứng',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'totalServiceFee',
      label: 'Phí',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'totalTransaction',
      label: 'Số GD',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'term',
      label: 'Kỳ hạn',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'salaryDate',
      label: 'Ngày chi lương',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'status',
      label: 'trạng thái',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    {
      key: 'createdAt',
      label: 'Ngày đăng ký',
      thStyle: 'padding: 0.72rem 1rem;vertical-align: middle;',
      tdClass: 'p-1',
    },
    { key: 'actions', thStyle: 'width:50px', label: '' },
  ];
  const perPage = ref(10);
  const totalContract = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const typeFilter = ref(null);
  const endDate = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalContract.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, typeFilter, endDate], () => {
    refetchData();
  });

  const fetchPayrolls = (ctx, callback) => {
    const queries = router.currentRoute.query;

    store
      .dispatch('app-payroll/fetchPayrolls', {
        limit: perPage.value,
        page: currentPage.value,
        type: typeFilter.value,
        endDate: endDate.value,
        ...queries,
      })
      .then((response) => {
        const { data, meta } = response.data;
        callback(data);
        totalContract.value = meta.total;
      })
      .catch((error) => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserStatusVariant = (value) => {
    if (value === 0) return 'success';
    if (value === 1) return 'purple';
    if (value === 2 || value === 3 || value === 7) return 'dark';
    if (value === 4) return 'primary';
    if (value === 5) return 'info';
    if (value === 6 || value === 9) return 'warning';
    if (value === 8 || value === 10) return 'danger';
    if (value === 11) return 'orange';
    return 'primary';
  };

  const resolveStatus = (value) => {
    if (value === 0) return 'Mới';
    if (value === 1) return 'Chờ GĐ duyệt';
    if (value === 2) return 'Từ chối';
    if (value === 3) return 'KH hủy';
    if (value === 4) return 'Chờ đầu tư';
    if (value === 5) return 'Chờ giải ngân';
    if (value === 6) return 'Thu nợ';
    if (value === 7) return 'Đóng';
    if (value === 8) return 'Trễ';
    if (value === 9) return 'Đang tải lên';
    if (value === 10) return 'Tạo thất bại';
    if (value === 11) return 'Chờ duyệt';
    return '';
  };

  const statusOptions = [
    { label: 'Mới', value: 0 },
    { label: 'Đang tải lên', value: 9 },
    { label: 'Chờ duyệt', value: 11 },
    { label: 'Chờ GĐ duyệt', value: 1 },

    { label: 'Chỡ đầu tư', value: 4 },
    { label: 'Chờ giải ngân', value: 5 },
    { label: 'Thu nợ', value: 6 },
    { label: 'Đóng', value: 7 },
    { label: 'Trễ', value: 8 },

    { label: 'Tạo thất bại', value: 10 },
    { label: 'Từ chối', value: 2 },
    { label: 'KH hủy', value: 3 },
  ];

  const typeOptions = [
    { label: 'Khoản ứng', value: 0 },
    { label: 'Lead', value: 2 },
  ];
  return {
    fetchPayrolls,
    tableColumns,
    perPage,
    currentPage,
    totalContract,
    dataMeta,
    perPageOptions,
    refUserListTable,
    statusOptions,
    typeOptions,
    typeFilter,
    resolveUserStatusVariant,
    refetchData,
    resolveStatus,
  };
}
