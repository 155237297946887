<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <payroll-filter-input query="code" title="Mã hợp đồng" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <payroll-filter-input query="companyName" title="Tên công ty" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <payroll-filter-dropdown :options="statusOptions" query="status" title="Trạng thái" />
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
          <payroll-filter-date query="createdAt" title="Ngày đăng ký" />
        </b-col>
        <b-col cols="12" md="2">
          <b-button variant="outline-primary" @click="handleCancel()">Hủy tìm kiếm</b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormDatepicker,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import PayrollFilterDropdown from './filter/PayrollFilterDropdown.vue';
import PayrollFilterInput from './filter/PayrollFilterInput.vue';
import PayrollFilterDate from './filter/PayrollFilterDate.vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormInput,
    BButton,
    BFormDatepicker,
    vSelect,
    PayrollFilterInput,
    PayrollFilterDropdown,
    PayrollFilterDate,
  },
  props: {
    statusFilter: {
      type: Number,
      default: null,
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    code: {
      type: String,
      default: '',
    },
    companyName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      debounce: null,
    };
  },
  methods: {
    debounceSearch(id, val) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit(`update:${id}`, val);
      }, 1000);
    },
    handleCancel() {
      const { query } = this.$router.currentRoute;
      if (query && Object.keys(query).length) {
        this.$router.replace({
          ...this.$router.currentRoute,
          query: {},
        });
        this.$eventBus.emit('payroll:filter:clear');
        this.$eventBus.emit('payroll:filter:update');
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
